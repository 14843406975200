<template>
  <div class="cont-box">
    <img src="../assets/c-bg-top.png">
    <img src="../assets/c-bg-bottom.png" class="img01">
    <div class="cont">
      <div class="header">
        <img class="logo" src="../assets/c-logo.png">
        <img class="title" src="../assets/c-title.png">
        <p class="title-d">【受理员工、供应商投诉】</p>
      </div>
      <el-form label-position="top" label-width="80px" :rules="rules" ref="subData" :model="subData">
        <el-form-item label="主题:" prop="subject">
          <el-input type="textarea" v-model="subData.subject" placeholder="请描述你的主题"></el-input>
        </el-form-item>
        <el-form-item label="涉及公司、部门及具体人员:" prop="involve">
          <el-input v-model="subData.involve" placeholder="格式: 公司-部门-人员"></el-input>
        </el-form-item>
        <el-form-item label="投诉内容:" prop="complaint">
          <el-input type="textarea" rows="4" v-model="subData.complaint" placeholder="请描述你问题和意见(尽量详细并附证)"></el-input>
        </el-form-item>
        <el-form-item label="附件上传:" prop="path">
          <el-upload :action="axios.defaults.baseURL+'/fleUploadAndDownload/FileUpload'" v-model="subData.path"
            :file-list="fileList" :auto-upload="true" :limit="1" ref="upload" :on-exceed="handleExceed"
            :on-remove="handleRemove" :on-success="handleSuccess" class="my-upload">
            <el-button type="infor" v-model="subData.path">上传附件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="您的姓名及联系方式:" prop="name">
          <el-input v-model="subData.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item prop="contact">
          <el-input v-model="subData.contact" placeholder="联系方式" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="companyOrDepartment">
          <el-input v-model="subData.companyOrDepartment" placeholder="部门/公司"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('subData')" class="sub-btn">提&nbsp;&nbsp;交</el-button>
        </el-form-item>
      </el-form>
      <div class="der">
        <p><span>公司禁止行为及投诉受理范围：</span><br />
          1.禁止干部不作为，拉帮结派，以权谋私<br />
          2.禁止对上弄虚作假，对下打击报复<br />
          3.禁止权利交易，腐败行为<br />
          4.禁止用合理之名做不公正之事<br />
        </p>
        <p>实名投诉，不受理匿名，禁止恶意诽谤，个人信息公司严格保密</p>
      </div>
    </div>
    <div class="pop" v-if="pop">
      <div class="pop-cont">
        <p>投诉须知</p>
        <p>实名投诉，禁止恶意诽谤，个人信息公司严格保密</p>
        <p>(受理对象员工、供应商)</p>
        <el-button type="primary" class="pop-btn" @click="pop = false">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        axios: axios,
        pop: true,
        fileList: [],
        subData: {
          subject: '',
          involve: '',
          complaint: '',
          path: '',
          name: '',
          contact: '',
          companyOrDepartment: ''
        },
        rules: {
          subject: [{
            required: true,
            message: '请填写',
            trigger: 'blur'
          }],
          involve: [{
            required: true,
            message: '请填写',
            trigger: 'blur'
          }],
          complaint: [{
            required: true,
            message: '请填写',
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '请填写',
            trigger: 'blur'
          }],
          contact: [{
            required: true,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }],
          companyOrDepartment: [{
            required: true,
            message: '请填写',
            trigger: 'blur'
          }],
        }
      }
    },
    mounted() {

    },
    methods: {
      handleExceed(files, fileList) {
        this.$message({
          message: '超出最大上传数',
          type: 'warning',
          center: true,
          customClass: 'my-mes',
          duration: 1000
        })
      },
      handleRemove(file) {
        this.$refs.upload.clearFiles()
        this.subData.path = ''
      },
      handleSuccess(res, file) {
        this.subData.path = res.data
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post('/complaints-suggestions/save', this.subData).then(res => {
              this.$refs[formName].resetFields()
              this.$refs.upload.clearFiles()
              this.$alert('提交成功，将有工作人员处理！', '', {
                confirmButtonText: '确定',
                customClass: 'my-alert',
                center: true,
                callback: action => {}
              })
            })
          } else {
            console.log('error submit!!');
            return false
          }
        })
      }
    }
  }
</script>

<style scoped>
  .cont-box {
    position: relative;
    /* height: 100%; */
  }

  .cont-box>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .cont-box>img.img01 {
    bottom: 0;
    top: auto;
  }

  .el-form {
    padding: 0 2rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .logo {
    width: 40%;
    padding: 2rem 0 1rem;
  }

  .title {
    width: 60%;
  }

  .title-d {
    color: #e8380d;
    padding: 0.8rem 0;
  }

  .el-form--label-top>>>.el-form-item__label {
    padding: 0;
    font-weight: bold;
  }

  .el-button {
    width: 100%;
  }

  .el-button.sub-btn {
    margin: 1rem 0 2rem;
    background-color: #e8320d;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 0;
  }

  .el-button.pop-btn {
    width: 6rem;
    background-color: #e8320d;
    border: none;
    margin: 1rem 0 .5rem;
  }

  .pop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pop-cont {
    box-sizing: border-box;
    width: 72%;
    height: 16rem;
    background: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
  }

  .pop-cont>p:nth-of-type(1) {
    font-size: 1.2rem;
    font-weight: bold;
    margin: .5rem 0 1rem;
  }

  .pop-cont>p:nth-of-type(2) {
    color: #e8320d;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: .5rem;
  }

  .pop-cont>p:nth-of-type(3) {
    color: #646464;
    text-align: center;
    line-height: 2rem;
  }

  .der {
    padding: 0 2rem 2rem;
  }

  .der>p {
    line-height: 1.8rem;
    color: #e8320d;
  }

  .der>p>span:nth-of-type(1) {
    font-weight: bold;
  }

  .der>p:nth-of-type(2) {
    margin-top: .5rem;
    font-size: .8rem;
    color: #646464;
    line-height: 1.2rem;
  }

  .my-upload>>>.el-upload {
    width: 100%;
  }
  
  .el-input >>> .el-input__inner, .el-textarea >>> .el-textarea__inner {
    color: #323232;
  }
</style>
